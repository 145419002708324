import { useEffect, useState } from "react";

import Input from "../../components/Inputs/Input";
import DropDownList from "../../components/Inputs/DropDownList";
import InputRange from "../../components/Inputs/InputRange";
import CheckBox from "../../components/Inputs/Checkbox";

import css from "./styles.module.scss";
import Icon, { ColorIconEnam, IconEnum } from "../../components/StateLess/Icon";

const {
   application__stepBlock,
   application__sign,
   application__signHeader,
   application__signBlock,
   application__signBlockCheck,
   application__signBlockText,
   application__signNote,

   application__ruleHiddenText,
   application__ruleHiddenIcon,
   application__ruleHiddenIcon_open,
   application__ruleHiddenContainer
} = css;

const ApplicationStep3 = ({ onChange = () => {}, data = {}, errors = {}, settings = {}, hasSend = false }) => {
   
   const [jobType, setJobType] = useState(data.jobType || data.jobType === 0 ? data.jobType : 0);
   const jobTypeArray = ["Работаю", "Не работаю", "Пенсионер"];

   const [jobPlace, setJobPlace] = useState(data.jobPlace ? data.jobPlace : "");
   const [jobPosition, setJobPosition] = useState(data.jobPosition ? data.jobPosition : "");

   const [jobAddress, setJobAddress] = useState(data.jobAddress ? data.jobAddress : "");

   const [jobPhone, setJobPhone] = useState(data.jobPhone ? data.jobPhone : "");
   const [nextIncome, setNextIncome] = useState(data.nextIncome || data.nextIncome === 0 ? data.nextIncome : 0);
   const nextIncomeArray = [
      "1го числа",
      "2го числа",
      "3го числа",
      "4го числа",
      "5го числа",
      "6го числа",
      "7го числа",
      "8го числа",
      "9го числа",
      "10го числа",
      "11го числа",
      "12го числа",
      "13го числа",
      "14го числа",
      "15го числа",
      "16го числа",
      "17го числа",
      "18го числа",
      "19го числа",
      "20го числа",
      "21го числа",
      "22го числа",
      "23го числа",
      "24го числа",
      "25го числа",
      "26го числа",
      "27го числа",
      "28го числа",
      "29го числа",
      "30го числа",
      "31го числа",
   ];

   const [income, setIncome] = useState(data.income || data.income === 0 ? data.income : 0);
   const incomeArray = [
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const [loans, setLoans] = useState(data.loans || data.loans === 0 ? data.loans : 1);
   const loansArray = [
      "Нет платежей по кредитам",
      "До 10 000 ₽",
      "10 000 ₽ - 20 000 ₽",
      "20 000 ₽ - 35 000 ₽",
      "35 000 ₽ - 50 000 ₽",
      "Более 50 000 ₽",
   ];

   const [amount, setAmount] = useState(-1);
   const amountArray = [];
   for (let i = (settings.min_amount ? parseFloat(settings.min_amount) : 1000); i <= (settings.max_amount ? parseFloat(settings.max_amount) : 30000); i += 1000) amountArray.push(i);

   const [duration, setDuration] = useState(-1);
   const durationArray = [];
   for (let i = (settings.min_duration ? parseFloat(settings.min_duration) : 7); i <= (settings.max_duration ? parseFloat(settings.max_duration) : 30); i++) durationArray.push(i);

   useEffect(() => {

      let amountSet = false;

      amountArray.forEach((value, i) => {
         if (value === data.amount) {
            setAmount(i);
            amountSet = true;
         }
      });

      if (!amountSet) setAmount(0);
      
      let durationSet = false;
      
      durationArray.forEach((value, i) => {
         if (value === data.duration) {
            setDuration(i);
            durationSet = true;
         }
      });
      
      if (!durationSet) setDuration(0);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [rule, setRule] = useState(false);
   const [ruleKomf, setRuleKomf] = useState(false);
   const [ruleUstupka, setRuleUstupka] = useState(false);
   const [rulePersDate, setRulePersDate] = useState(false);
   const [ruleAsp, setRuleAsp] = useState(false);

   const [ruleRekurent, setRuleRekurent] = useState(false);
   const [ruleKrediDoctor, setRuleKrediDoctor] = useState(false);
   const [ruleSlonFin, setRuleSlonFin] = useState(false);

   useEffect(() => {
      onChange({
         jobType,
         jobPlace,
         jobPosition,
         jobAddress,
         jobPhone,
         nextIncome,
         income,
         loans,
         amount: amountArray[amount],
         duration: durationArray[duration],
         rule,
         ruleKomf,
         ruleUstupka,
         rulePersDate,
         ruleAsp,

         ruleRekurent,
         ruleKrediDoctor,
         ruleSlonFin
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      jobType,
      jobPlace,
      jobPosition,
      jobAddress,
      jobPhone,
      nextIncome,
      income,
      loans,
      amount,
      duration,
      rule,
      ruleKomf,
      ruleUstupka,
      rulePersDate,
      ruleAsp,

      ruleRekurent,
      ruleKrediDoctor,
      ruleSlonFin
   ]);

   const [showRules, setShowRules] = useState(false);

   return (
      <>
         <div className={application__stepBlock}>
            <DropDownList label={"Вид занятости"} options={jobTypeArray} value={jobType} onChange={setJobType} />
         </div>
         {jobType === 0 ? (
            <>
               <div className={application__stepBlock}>
                  <Input
                     placeholder={"Место работы"}
                     value={jobPlace}
                     onChange={setJobPlace}
                     error={errors.jobPlace ? errors.jobPlace : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <Input
                     placeholder={"Занимаемая должность"}
                     value={jobPosition}
                     onChange={setJobPosition}
                     error={errors.jobPosition ? errors.jobPosition : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     placeholder={"Адрес организации"}
                     value={jobAddress}
                     onChange={setJobAddress}
                     error={errors.jobAddress ? errors.jobAddress : null}
                     showErrorOnBlur={!hasSend}
                  />
               </div>
               <div className={application__stepBlock}>
                  <Input
                     placeholder={"Номер телефона организации"}
                     value={jobPhone}
                     onChange={setJobPhone}
                     mask="+7 (XXX) XXX-XX-XX"
                     regex={/^[0-9]$/}
                     error={errors.jobPhone ? errors.jobPhone : null}
                     showErrorOnBlur={!hasSend}
                  />
                  <DropDownList
                     label={"Ближайшая выплата"}
                     options={nextIncomeArray}
                     value={nextIncome}
                     onChange={setNextIncome}
                  />
               </div>
            </>
         ) : (
            ""
         )}
         <div className={application__stepBlock}>
            <DropDownList label={"Ежемесячный доход"} options={incomeArray} value={income} onChange={setIncome} />
         </div>
         <div className={application__stepBlock}>
            <DropDownList label={"Кредитные обязательства"} options={loansArray} value={loans} onChange={setLoans} />
         </div>
         <div className={application__stepBlock}>
            {
               amount !== -1 ? (
                  <InputRange
                     label="Сумма"
                     unit="₽"
                     ariaLabel="Сумма займа"
                     length={amountArray.length - 1}
                     step={1}
                     value={amount}
                     list={amountArray}
                     onChange={setAmount}
                  />
               ) : "1"
            }
            {
               duration !== -1 ? (
                  <InputRange
                     label="Срок"
                     unit="дней"
                     ariaLabel="Срок займа"
                     length={durationArray.length - 1}
                     step={1}
                     value={duration}
                     list={durationArray}
                     onChange={setDuration}
                  />
               ) : "2"
            }
         </div>

         <div className={application__sign}>
            <h2 className={application__signHeader}>Подписание заявки</h2>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || (ruleAsp && ruleKomf && rulePersDate && ruleUstupka && ruleRekurent && ruleSlonFin && ruleKrediDoctor)}
                  onChange={setRule}
               />
               <p className={application__signBlockText}>Я согласен со всеми условиями</p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleKomf} onChange={setRuleKomf} />
               <p className={application__signBlockText}>
                  Настоящим подтверждаю, что полностью ознакомлен с условиями предоставления микрозайма, а так же с&nbsp;
                  <a href="/dokumenti" target="_blank" rel="noopener noreferrer">
                     публичной офертой
                  </a>{" "}
                  и общими условиями.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || ruleUstupka}
                  onChange={setRuleUstupka}
               />
               <p className={application__signBlockText}>
                  Настоящим соглашаюсь на уступку прав (требований) по договору третьим лицам при условии соблюдения
                  Обществом требований действующего законодательства.
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox
                  className={application__signBlockCheck}
                  value={rule || rulePersDate}
                  onChange={setRulePersDate}
               />
               <p className={application__signBlockText}>
                  Настоящим выражаю своё согласие на обработку{" "}
                  <a href="/politika-konfidencialnosti" target="_blank" rel="noopener noreferrer">
                     персональных данных и на запрос кредитного отчёта в бюро кредитных историй
                  </a>
                  .
               </p>
            </label>

            <label className={application__signBlock}>
               <CheckBox className={application__signBlockCheck} value={rule || ruleAsp} onChange={setRuleAsp} />
               <p className={application__signBlockText}>
                  Настоящим выражаю своё согласие на принятие условий{" "}
                  <a href="/dokumenti" target="_blank" rel="noopener noreferrer">
                     Соглашения об использовании АСП
                  </a>
                  , <span
                        className={application__ruleHiddenText}
                        title="Показать ещё"
                        onClick={e => {
                           e.preventDefault();
                           setShowRules(!showRules);
                        }}
                    >а также <Icon
                                 className={application__ruleHiddenIcon + (showRules ? " " + application__ruleHiddenIcon_open : "")}
                                 name={IconEnum.chewron_right}
                                 color={ColorIconEnam.base}
                             />
                     </span>
               </p>
            </label>

            <div className={application__ruleHiddenContainer} style={!showRules ? {maxHeight: "0px"} : null}>
               <label className={application__signBlock}>
                  <CheckBox className={application__signBlockCheck} value={rule || ruleAsp || ruleRekurent} onChange={setRuleRekurent} />
                  <p className={application__signBlockText}>
                     согласие на{" "}
                     <a href="/dokumenti" target="_blank" rel="noopener noreferrer">
                        на запрос кредитной истории в бюро "Спектрум кредитное бюро"
                     </a>
                     ,
                  </p>
               </label>

               <label className={application__signBlock}>
                  <CheckBox className={application__signBlockCheck} value={rule || ruleAsp || ruleSlonFin} onChange={setRuleSlonFin} />
                  <p className={application__signBlockText}>
                     согласие с{" "}
                     <a href="/dokumenti" target="_blank" rel="noopener noreferrer">
                        публичной офертой ООО "Новая Страница"
                     </a>
                     ,
                  </p>
               </label>

               <label className={application__signBlock}>
                  <CheckBox className={application__signBlockCheck} value={rule || ruleAsp || ruleKrediDoctor} onChange={setRuleKrediDoctor} />
                  <p className={application__signBlockText}>
                     согласие с{" "}
                     <a href="/dokumenti" target="_blank" rel="noopener noreferrer">
                        на передачу персональных  данных в сервис "Скориста"
                     </a>
                     .
                  </p>
               </label>
            </div>

            <p className={application__signNote}>
               Я даю своё согласие на то, что заполнив свединия в&nbsp;Анкете-заявлений на&nbsp;получение потребительского займа,
               я&nbsp;подтверждаю достоверность предоставленных мною сведений и&nbsp;считаю анкету подписанной со своей стороны.
            </p>
         </div>
      </>
   );
};

export default ApplicationStep3;
